<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        :disabled="isTongchou && selectionRows.some(item => item.creator.realname != userInfo.nickName)"
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        >删除</a-button
      >
      <a-button
        v-if="!isTongchou"
        :disabled="
          selectionRows.some(item => selectionRows[0].help.viewable != item.help.viewable) ||
            selectionRows.every(item => item.help.viewable)
        "
        class="mr-3"
        @click="start"
        >启用</a-button
      >
      <a-button
        v-if="!isTongchou"
        :disabled="
          selectionRows.some(item => selectionRows[0].help.viewable != item.help.viewable) ||
            selectionRows.every(item => !item.help.viewable)
        "
        class="mr-3"
        @click="stop"
        >停用</a-button
      >
      <a-button type="primary" @click="addQuestion">新建</a-button>
    </div>
    <div class="table-page-search-wrapper" v-has="'statistics:settlement:search'" slot="search">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row type="flex" :gutter="16">
          <a-col>
            <a-input v-model="queryParam.title" placeholder="请输入问题" />
          </a-col>
          <a-col>
            <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <a-table
      ref="table"
      id="out-table"
      size="middle"
      :rowKey="record => record.help.id"
      :columns="columns"
      :customRow="clickRow"
      :dataSource="dataSource"
      :pagination="ipagination"
      :loading="loading"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 362px)' }"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 45 }"
      @change="handleTableChange"
    >
      <span slot="title" slot-scope="text, record">{{ record && record.help && record.help.title }}</span>
      <span slot="content" slot-scope="text, record">{{ record && record.help && record.help.content }}</span>
      <span slot="viewable" slot-scope="text, record">
        <a-icon v-if="record && record.help && record.help.viewable" :style="{ color }" type="check-circle" />
        <a-icon v-else style="color:red" type="close-circle" />
        {{ record && record.help && record.help.viewable ? '已启用' : '已停用' }}</span
      >
      <span slot="realname" slot-scope="text, record">
        <img v-if='record.creator' style="width:30px;height:30px;border-radius:50%;margin-right:10px;" :src="record.creator.avatar" alt="" />
        {{ record && record.creator && record.creator.realname }}
      </span>
      <span slot="gmtCreate" slot-scope="text, record">{{ record && record.help && record.help.gmtCreate }}</span>
    </a-table>
    <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <a-form-model ref="form" :model="formData" :rules="rules" v-bind="layout">
        <a-form-model-item
          class="upload-item"
          label="问题标题"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          prop="title"
        >
          <a-textarea :auto-size="{ minRows: 1, maxRows: 3 }" v-model="formData.title" placeholder="请输入问题标题" />
        </a-form-model-item>
        <a-form-model-item
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          label="问题结果"
          class="first-form-item"
          prop="content"
        >
          <a-textarea :auto-size="{ minRows: 1, maxRows: 3 }" v-model="formData.content" placeholder="请输入问题结果" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import Vue from 'vue'
import { USER_INFO } from '@/store/mutation-types'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
export default {
  mixins: [JeecgListMixin],
  data() {
    return {
      title: '新建',
      visible: false,
      formData: {},
      layout: {
        labelCol: {
          span: 9
        },
        wrapperCol: {
          span: 15
        }
      },
      wrapperCol: {
        span: 16
      },
      labelCol: {
        span: 8
      },
      rules: {
        content: [{ required: true, message: '请输入问题结果', trigger: 'blur' }],
        title: [{ required: true, message: '请输入问题标题', trigger: 'blur' }]
      },
      columns: [
        {
          title: '问',
          dataIndex: 'title',
          width: 400,
          scopedSlots: { customRender: 'title' }
        },
        {
          title: '答',
          dataIndex: 'content',
          width: 400,
          scopedSlots: { customRender: 'content' }
        },
        {
          title: '状态',
          dataIndex: 'viewable',
          width: 180,
          scopedSlots: { customRender: 'viewable' }
        },
        {
          title: '添加人',
          dataIndex: 'realname',
          width: 180,
          scopedSlots: { customRender: 'realname' }
        },
        {
          title: '添加时间',
          dataIndex: 'gmtCreate',
          width: 200,
          scopedSlots: { customRender: 'gmtCreate' }
        }
      ],
      url: {
        list: '/help/page/admin'
      },
      isTongchou: false,
      isEdit: false,
      userInfo: {},
      color: '',
      clickRow: (record, index) => ({
        on: {
          click: () => {
            this.openDetail(record)
          }
        }
      })
    }
  },
  created() {
    this.userInfo = Vue.ls.get(USER_INFO)
    let roles = this.userInfo.roles || []
    roles.forEach(element => {
      if (['tongchou'].indexOf(element.roleCode) >= 0) {
        this.isTongchou = true
      }
    })
  },
  mounted() {
    this.color = this.$store.state.app.color
  },
  methods: {
    openDetail(record) {
      if (this.isTongchou && record.creator.realname != this.userInfo.nickName) {
        return
      } else {
        this.visible = true
        this.isEdit = true
        this.formData = {
          id: record.help.id,
          title: record.help.title,
          content: record.help.content
        }
      }
    },
    handleOk() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          if (!this.isEdit) {
            const res = await postAction('/help', this.formData)
            if (res.code == 200) {
              this.$message.success('新建成功')
              this.handleCancel()
              this.loadData()
            } else {
              this.$message.error(res.msg || res.message)
            }
          } else {
            const res = await putAction('/help', this.formData)
            if (res.code == 200) {
              this.$message.success('保存成功')
              this.handleCancel()
              this.loadData()
            } else {
              this.$message.error(res.msg || res.message)
            }
          }
        } else {
          return false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.formData = {}
      this.onClearSelected()
    },
    async start() {
      let data = []
      console.log(this.selectionRows)
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).help.id)
      })
      data = data.join(',')
      const res = await putAction('/help/enableOrUnable?ids=' + data)
      if (res.code == 200) {
        this.$message.success('已启用')
        this.loadData()
        this.onClearSelected()
      }
    },
    async stop() {
      let data = []
      console.log(this.selectionRows)
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).help.id)
      })
      data = data.join(',')
      const res = await putAction('/help/enableOrUnable?ids=' + data)
      if (res.code == 200) {
        this.$message.success('已停用')
        this.loadData()
        this.onClearSelected()
      }
    },
    addQuestion() {
      this.isEdit = false
      this.visible = true
    },
    batchDel: function() {
      let data = []
      let that = this
      console.log(this.selectionRows)
      if (!this.selectionRows.length) {
        this.$message.warning('请选择至少一条数据！')
        return
      }
      this.selectionRows?.forEach(item => {
        data.push(JSON.parse(JSON.stringify(item)).help.id)
      })
      data = data.join(',')
      this.$confirm({
        title: '确认删除',
        content: '是否删除选中数据?',
        onOk: function() {
          that.loading = true
          deleteAction('/help?ids=' + data)
            .then(res => {
              if (res.success) {
                that.$message.success(res.message || res.msg ? '删除成功' : '')
                that.loadData()
              } else {
                that.$message.warning(res.message || res.msg)
              }
            })
            .finally(() => {
              that.loading = false
            })
        }
      })
    }
  }
}
</script>

<style></style>
